import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { aboutUsLinks } from 'components/Layout/navigation';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import ArrowButton from 'components/Button/ArrowButton';
import Layout from 'components/Layout';
import TabNavigator from 'components/TabNavigator';
import { HeaderMedium } from 'components/typography';
import { MobileFilter } from 'components/BackgroundFilter';
import { WidthWrapper } from 'components/wrappers';
import { HeroSection } from 'components/wrappers';
import { BasicDiv } from 'components/wrappers';
import { TextDesc } from 'components/wrappers';
import HeroBg from 'images/all-in-one/all-in-one-bg.png';
import ShipkooAllInOne from 'images/all-in-one/shipkoo-all-in-one.png';
import MultiChannel from 'images/all-in-one/multi-channel.png';
import EbayHoliday from 'images/holiday-shipping/ebay-holiday-deadlines.png';
import WarehouseBG from 'images/fulfillment-centers-vs-warehouse/warehouse.png';
import FulfillmentBG from 'images/fulfillment-centers-vs-warehouse/fulfillment-centers.png';
import UserFriendlyImg from 'images/all-in-one/user-friendly.png';
import ListImage from 'images/all-in-one/list-image.png';
import OrderManagementImg from 'images/all-in-one/order-management.png';
import DataImportingFeature from 'images/all-in-one/data-importing-feature.png';
import InventoryManagementFeature from 'images/all-in-one/inventory-management-feature.png';
import MultipleFulfillmentFeature from 'images/all-in-one/multiple-fulfillment-feature.png';
import OrderTrackingFeature from 'images/all-in-one/order-tracking-feature.png';
import ShippingCalculatorFeature from 'images/all-in-one/shipping-calculator-feature.png';
import ShoppingLabelsFeature from 'images/all-in-one/shipping-labels-feature.png';


import {UnorderedList} from 'components/blog/SingleBlogStyle';

import { DetailContent, PageContainer, MainSection, ShippingSectionURL, AllInOneSection }  from 'components/pages/Resources/AllInOne';

const ContentSmall = styled.p`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:25px;
`;

const ContentSmallTitle = styled.p`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:0px;
`;

const ContentSmallBg = styled.p`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:25px;
  background: #FCF1DE;
  text-align: center;
  border-bottom:2px solid #000;
`;

const ContentSmallCenter = styled.p`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:10px;
  text-align: center;
`;

const ContentSmallSpan = styled.span`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
`;

const Clear  = styled.div`
  clear:both;
  padding:0 !important;
`;

const Row  = styled.div`
  max-width:1300px;
  margin-left:auto;
  margin-right:auto;
`;

const TabNavigation = styled.div`
  width:25%;
  display:inline-block;

`;

const HeroHeader = styled.h1`
  font-size: 35px;
  margin-bottom: 20px;
`;

const HeroText = styled.p`
  font-size: 14px;
  font-weight: 300;
  color: #7f7f7f;
  line-height:1.8;
`;
const Breadcrumbs = styled.p`
    padding: 0px 0px 25px 0px;
`;

const AllInOnePlatform = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Helmet>
        {/* <title>All-in-One Digital Platform - Inventory, Orders and Shipping | Shipkoo</title> */}
        <title>{intl.formatMessage({ id: "resources-all-in-one-digital-platform" })}</title>
        <link rel="alternate" href={intl.formatMessage({ id: "aio-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "aio-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "aio-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "aio-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="" />
        <meta property="og:title" content={intl.formatMessage({ id: "resources-all-in-one-digital-platform" })} />
        <meta property="og:description" content="" />
        <meta name="twitter:title" content={intl.formatMessage({ id: "resources-all-in-one-digital-platform" })} />
        <meta name="twitter:description" content="" />
      </Helmet>
      <PageContainer>
        <MainSection>
          <div id="hero-section" className={intl.formatMessage({ id: "language" })}>
            <img className="hero-bg" src={HeroBg} alt="all in one digital platform"/>
            <Row>
                <div className="column-half">
                  <div className="content-container">
                    <Breadcrumbs><Link to="/">Home</Link> / {intl.formatMessage({ id: "menu-all-in-one-digital-platform" })}</Breadcrumbs>
                    <HeroHeader>{intl.formatMessage({ id: "aio-header" })}</HeroHeader>
                    <HeroText>{intl.formatMessage({ id: "aio-description" })}</HeroText>
                  </div>
                </div>
                <div className="column-half last">
                  <div className="content-container">
                    <img src={ShipkooAllInOne} alt="shipkoo all in one" />
                  </div>
                </div>
                <Clear></Clear>
            </Row>
          </div>  
        </MainSection>        
        <ShippingSectionURL>
          <Row>
            <div id="tab-navigation">
              <TabNavigation>
                <a href="#user-friendly">
                  <h3>{intl.formatMessage({ id: "aio-user-friendly" })}</h3>
                </a>
              </TabNavigation>
              <TabNavigation>
                <a href="#multi-channel">
                  <h3>{intl.formatMessage({ id: "aio-multi-channel" })}</h3>
                </a>
              </TabNavigation>
              <TabNavigation>
                <a href="#order-management">
                  <h3>{intl.formatMessage({ id: "aio-order-management" })}</h3>
                </a>
              </TabNavigation>
              <TabNavigation>
                <a href="#platform-features">
                  <h3>{intl.formatMessage({ id: "aio-platform-features" })}</h3>
                </a>
              </TabNavigation>
              <Clear></Clear>
            </div> 
          </Row>
        </ShippingSectionURL>
        <AllInOneSection>
          <div id="user-friendly">
            <Row>
              <div className="column-half">
                <img className="" src={UserFriendlyImg} alt="user friendly" />
              </div>
              <div className="column-half last">
                <h2>
                  {intl.formatMessage({ id: "aio-user-friendly-title" })}
                </h2>
                <ContentSmall>
                  {intl.formatMessage({ id: "aio-user-friendly-desc" })}
                </ContentSmall>
              </div>
              <Clear></Clear>
            </Row>
          </div>
        </AllInOneSection>   
        <AllInOneSection>
          <div id="multi-channel">
            <Row>
              <div className="column-half">
                <h2>
                  {intl.formatMessage({ id: "aio-multi-channel-title" })}
                </h2>
                <ContentSmall>
                  {intl.formatMessage({ id: "aio-multi-channel-desc" })} 
                </ContentSmall>
              </div>
              <div className="column-half last">
                <img src={MultiChannel} alt="multi channel" />
              </div>
              <Clear></Clear>
            </Row> 
          </div>
        </AllInOneSection>
        <AllInOneSection>
          <div id="order-management">
            <Row>
              <div className="column-half whole">
                <img className="" src={OrderManagementImg} alt="order-management" />
              </div>
              <div className="column-half last whole">
                <h2>
                  {intl.formatMessage({ id: "aio-order-management-title" })}
                </h2>
                <ContentSmall>
                  {intl.formatMessage({ id: "aio-order-management-desc" })}
                </ContentSmall>
                <div className="column-half">
                  <UnorderedList>
                    <li>
                      <img src={ListImage} className="list-image" alt = "List icon" />{intl.formatMessage({ id: "aio-order-management-list-1" })}
                    </li>
                    <li>
                      <img src={ListImage} className="list-image" alt = "List icon" />{intl.formatMessage({ id: "aio-order-management-list-2" })}
                    </li>
                    <li>
                      <img src={ListImage} className="list-image" alt = "List icon" />{intl.formatMessage({ id: "aio-order-management-list-3" })}
                    </li>
                    <li>
                      <img src={ListImage} className="list-image" alt = "List icon" />{intl.formatMessage({ id: "aio-order-management-list-4" })}
                    </li>
                  </UnorderedList>
                </div>
                <div className="column-half last">
                  <UnorderedList>
                    <li>
                      <img src={ListImage} className="list-image" alt = "List icon" />{intl.formatMessage({ id: "aio-order-management-list-5" })}
                    </li>
                    <li>
                      <img src={ListImage} className="list-image" alt = "List icon" />{intl.formatMessage({ id: "aio-order-management-list-6" })}
                    </li>
                    <li>
                      <img src={ListImage} className="list-image" alt = "List icon" />{intl.formatMessage({ id: "aio-order-management-list-7" })}
                    </li>
                    <li>
                      <img src={ListImage} className="list-image" alt = "List icon" />{intl.formatMessage({ id: "aio-order-management-list-8" })}
                    </li>
                  </UnorderedList>
                </div>
              </div>
              <Clear></Clear>
            </Row>
          </div>
        </AllInOneSection>
        <AllInOneSection>
          <div id="platform-features" className={intl.formatMessage({ id: "language" })}>
            <Row>
              <h2>
                {intl.formatMessage({ id: "aio-platform-features-title" })}
              </h2>
              <div className="column-third">
                <img src={DataImportingFeature} className="platform-image" alt = "Feature Importing Image" />
                <ContentSmallTitle>
                  <strong>{intl.formatMessage({ id: "aio-platform-features-title-1" })}</strong>
                </ContentSmallTitle>
                <ContentSmall>
                  {intl.formatMessage({ id: "aio-platform-features-1" })}
                </ContentSmall>
              </div>
              <div className="column-third">
                <img src={InventoryManagementFeature} className="platform-image" alt="Inventory Management Feature" />
                <ContentSmallTitle>
                  <strong>{intl.formatMessage({ id: "aio-platform-features-title-2" })}</strong>
                </ContentSmallTitle>
                <ContentSmall>
                  {intl.formatMessage({ id: "aio-platform-features-2" })}
                </ContentSmall>
              </div>
              <div className="column-third last">
                <img src={MultipleFulfillmentFeature} className="platform-image" alt="Multiple Fulfillment Feature" />
                <ContentSmallTitle>
                  <strong>{intl.formatMessage({ id: "aio-platform-features-title-3" })}</strong>
                </ContentSmallTitle>
                <ContentSmall>
                  {intl.formatMessage({ id: "aio-platform-features-3" })}
                </ContentSmall>
              </div>
              <Clear></Clear>
              <div className="column-half feature-column">
                <img src={OrderTrackingFeature} className="platform-image" alt="Order Tracking Feature" />
                <ContentSmallTitle>
                  <strong>{intl.formatMessage({ id: "aio-platform-features-title-4" })}</strong>
                </ContentSmallTitle>
                <ContentSmall>
                  {intl.formatMessage({ id: "aio-platform-features-4" })}
                </ContentSmall>
              </div>
              <div className="column-half last feature-column">
                <img src={ShippingCalculatorFeature} className="platform-image" alt="Shipping Calculator Feature" />
                <ContentSmallTitle>
                  <strong>{intl.formatMessage({ id: "aio-platform-features-title-5" })}</strong>
                </ContentSmallTitle>
                <ContentSmall>
                  {intl.formatMessage({ id: "aio-platform-features-5" })}
                </ContentSmall>
              </div>
              {/*<div className="column-third last">
                <img src={ShoppingLabelsFeature} className="platform-image" />
                <ContentSmallTitle>
                  <strong>{intl.formatMessage({ id: "aio-platform-features-title-6" })}</strong>
                </ContentSmallTitle>
                <ContentSmall>
                  {intl.formatMessage({ id: "aio-platform-features-6" })}
                </ContentSmall>
              </div>*/}
              <Clear></Clear>
            </Row> 
          </div>
        </AllInOneSection>
      </PageContainer>
    </Layout>
  );
};

export default AllInOnePlatform;
